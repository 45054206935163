<template>
  <!-- 供需关系 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('supply_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
    ></x-table>
    <choose-Model
      v-model="supplyShow"
      :title="supplyTitle"
      :DefluteValue="finishId"
      @refrestList="refrestList"
    >
    </choose-Model>
    <supply-Model
      v-model="DriverModel"
      :title="DriverTitle"
      :DefluteValue="DefluteValue"
      :defaultEdeit="defaultEdeit"
      @refrestList="refrestList"
    ></supply-Model>
    <car-detail
      v-model="transportModel"
      :title="transportTitle"
      :DefluteValue="transporValue"
      :defaultEdeit="defaultEdeit"
      @refrestList="refrestList"
    ></car-detail>
  </div>
</template>

<script>
import supplyModel from "./supplyModel";
import chooseModel from "./chooseModel";
import carDetail from "./orderModel";
export default {
  name: "",
  components: {
    supplyModel,
    chooseModel,
    carDetail,
  },
  data() {
    return {
      DriverModel: false,
      supplyShow: false,
      transportModel: false,
      transportTitle: "",
      transporValue: {},
      supplyTitle: "供应处理",

      finishId: 0,
      DriverTitle: "",
      DefluteValue: {},
      table: {
        columns: [
          {
            title: "货品名称",
            minWidth: 100,
            key: "demandName",
          },
          // {
          //   title: "供需类型",
          //   minWidth: 100,
          //   render: (h, { row }) => {
          //     return (
          //       <div>{row.demandType == "0" ? "转运需求" : "施用需求"}</div>
          //     );
          //   },
          // },
          {
            title: "货品备注",
            minWidth: 100,
            key: "demandRemark",
          },
          {
            title: "货物名称",
            minWidth: 100,
            key: "productName",
          },
          {
            title: "供应描述",
            minWidth: 100,
            key: "demandNumber",
          },
          {
            title: "数量",
            minWidth: 100,
            key: "demandNum",
          },

          {
            title: "单位类型",
            minWidth: 100,
            key: "demandUnit",
          },
          {
            title: "货品图片",
            minWidth: 100,
            render: (h, { row }) => {
              if (!row.demandImgs || !row.servicePath) return;
              return (
                <img
                  src={row.servicePath + row.demandImgs}
                  class="flexboxb"
                  style="width: 60px;height:40px;"
                />
              );
            },
          },
          {
            title: "供应地址",
            minWidth: 100,
            key: "demandAddress",
          },
          {
            title: "联系人",
            minWidth: 100,
            key: "telName",
          },
          {
            title: "联系电话",
            minWidth: 120,
            key: "telPhone",
          },
          {
            title: "状态",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <span>{row.demandStatus == "1" ? "发布中" : "已处理"}</span>
              );
            },
          },
          {
            title: "操作",
            width: 300,
            render: (h, { row }) => {
              return (
                <div>
                  {localStorage.userId == row.createUserId &&
                    this.CA("supply_finish") && (
                      <a
                        style="margin-right: 10px"
                        onClick={() =>
                          row.demandStatus == "1"
                            ? this.finishSupply(row.id)
                            : ""
                        }
                      >
                        {row.demandStatus == "1" ? "发布中" : "已处理"}
                      </a>
                    )}
                  {
                    //   this.CA("supply_update") && (
                    //   <a
                    //     style="margin-right: 10px"
                    //     onClick={() => this.edit(row)}
                    //   >
                    //     编辑
                    //   </a>
                    // )
                  }
                  {
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      供应详情
                    </a>
                  }
                  {(localStorage.getItem("userId") != row.createUserId) &&
                    (row.demandStatus == 1) &&
                    (
                      <a
                        style="margin-right: 10px"
                        onClick={() => this.releaseOrder(row)}
                      >
                        下单
                      </a>
                    )}
                  {localStorage.userId == row.createUserId &&
                    this.CA("supply_delete") && (
                      <Poptip
                        confirm
                        transfer
                        title="确定删除吗?"
                        on-on-ok={() => this.delete(row.id)}
                      >
                        <a>删除</a>
                      </Poptip>
                    )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增供应",
            ca: "supply_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "状态",
              component: "select",
              field: "demandStatus",
              defaultValue: "1",
              showField: "showField",
              parameterField: "parameterField",
              data: [
                { parameterField: "1", showField: "发布中" },
                { parameterField: "2", showField: "已处理" },
              ],
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      form: {},
      defaultEdeit: false,
      deptList: [],
      search_data: {
        demandStatus: 1,
      },
    };
  },
  methods: {
    //发布订单
    releaseOrder(row) {
      this.transportModel = true;
      this.transportTitle = "发布订单";
      this.transporValue = row;
    },
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.DriverModel = true;
      this.DriverTitle = "添加供应";
      this.defaultEdeit = false;
      this.transporValue = {};
      this.DefluteValue = {};
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    refrestList() {
      this.getList();
    },
    getList() {
      if (!this.CA("supply_check")) return;
      this.table.loading = true;
      this.$post(this.$api.SUPPLY_BIND.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        demandType: "0",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      this.defaultEdeit = false;
      this.DriverModel = true;
      this.DriverTitle = "修改供需";
      this.DefluteValue = row;
    },
    checkInfo(row) {
      this.defaultEdeit = true;
      this.DefluteValue = row;
      this.DriverModel = true;
      this.DriverTitle = "供需详情";
    },
    //需求处理列表
    finishSupply(id) {
      this.finishId = id;
      this.supplyShow = true;
    },
    //删除
    delete(id) {
      this.$post(this.$api.SUPPLY_BIND.DELETE, {
        id: id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    //获取部门列表
    getDeptList() {
      this.$post(this.$api.company.BARNCHTREE)
        .then((res) => {
          this.deptList = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getDeptList();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>