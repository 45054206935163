<template>
  <Modal
    :title="title"
    :value="value"
    @input="(val) => $emit('input', val)"
    :width="800"
    @on-visible-change="modalShow"
  >
    <Form
      :label-width="100"
      ref="form"
      :model="form"
      :rules="rules"
      style="display: flex; justify-content: center"
    >
      <Row>
        <Col span="12">
          <FormItem label="联系人" prop="receiveId">
            <Select
              v-model="form.receiveId"
              style="width: 460px"
              placeholder="请选择需求类型"
              clearable
            >
              <Option v-for="el in deptList" :key="el.id" :value="el.id">{{
                el.phone +
                "(" +
                "部门:" +
                el.roleName +
                ")" +
                "(" +
                "姓名:" +
                el.name +
                ")"
              }}</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <p slot="footer" style="text-align: center">
      <Button type="primary" @click="submit">提交</Button>
      <Button @click="() => $emit('input', false)">取消</Button>
    </p>
  </Modal>
</template>
<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    supplyList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    DefluteValue: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  data() {
    return {
      config: {
        filter: {
          add: {
            addBtnName: "添加供需",
            ca: "supply_add",
          },
          width: 200,
          // filterBox: [
          //   {
          //     conditionName: "姓名",
          //     component: "input",
          //     field: "name",
          //     defaultValue: "",
          //   },
          // ],
        },
      },
      deptList: [],
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: 0,
        receiveId: "",
      },
      rules: {},
    };
  },
  methods: {
    submit() {
      let params = this.form;
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(this.$api.SUPPLY_BIND.FINISH, params)
          .then((res) => {
            this.supplyShow = false;
            this.$emit("input", false);
            this.$emit("refrestList");
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: 0,
        receiveId: "",
      };
      this.$refs.form.resetFields();
    },
    //获取用户列表
    getProductList() {
      this.$post(this.$api.USER_MANAGE.ALLList)
        .then((res) => {
          this.deptList = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getProductList();
  },
  watch: {
    value(val) {
      if (val) {
        if (this.DefluteValue) {
          //执行初始化model操作
          let formData = {};
          //   formData = JSON.parse(JSON.stringify(this.DefluteValue));
          formData = this.DefluteValue;
          this.form.id = formData;
        }
      } else {
        //   执行重置数据操作
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>