<template>
  <!-- 司机管理 -->
  <div>
    <Modal
      :title="title"
      :value="value"
      @input="(val) => $emit('input', val)"
      :width="800"
      @on-visible-change="modalShow"
    >
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <Row>
          <Col span="12">
            <FormItem label="供应名称" prop="demandName">
              <Input
                :disabled="defaultEdeit"
                clearable
                placeholder="请输入需求名称"
                v-model="form.demandName"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="货物名称" prop="productId">
              <Select
                :disabled="defaultEdeit"
                v-model="form.productId"
                style="width: 260px"
                placeholder="请选择需求类型"
                clearable
              >
                <Option v-for="el in deptList" :key="el.id" :value="el.id">{{
                  el.name
                }}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="货物数量" prop="demandNum">
              <Input
                :disabled="defaultEdeit"
                style="width: 260px"
                v-model="form.demandNum"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="单位类型" prop="demandUnit">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入单位类型"
                v-model="form.demandUnit"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="联系人	" prop="telName">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入联系人"
                v-model="form.telName"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="联系电话" prop="telPhone">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入邮箱"
                v-model="form.telPhone"
                maxlength="11"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="所在基地" prop="publisherFarmId">
              <Select
                :disabled="defaultEdeit"
                @on-change="getAddress"
                v-model="form.publisherFarmId"
                style="width: 260px"
              >
                <Option
                  v-for="farm in farmList"
                  :key="farm.id"
                  :value="farm.id"
                >
                  {{ farm.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="基地地址" prop="demandAddress">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入供应地址"
                v-model="form.demandAddress"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <FormItem label="备注" prop="demandRemark">
          <Input
            clearable
            :disabled="defaultEdeit"
            type="textarea"
            placeholder="请输入需求要求"
            v-model="form.demandRemark"
            :rows="4"
          ></Input>
        </FormItem>
        <Row>
          <!-- <Col span="12"> -->
          <FormItem label="供需图片" :disabled="defaultEdeit">
            <img
              v-if="defaultEdeit"
              :src="form.demandImgs"
              style="width: 78px; height: 78px"
            />
            <upload-image
              v-else
              ref="upload"
              v-model="form.demandImgs"
              :max="1"
            />
          </FormItem>
          <!-- </Col> -->
          <!-- <Col span="12">
            <FormItem label="供应量描述" prop="demandNumber">
              <Input
                clearable
                type="textarea"
                :disabled="defaultEdeit"
                placeholder="请输入供应量描述"
                v-model="form.demandNumber"
                :row="5"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col> -->
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button v-if="!defaultEdeit" type="primary" @click="submit"
          >提交</Button
        >
        <Button @click="() => $emit('input', false)">{{
          defaultEdeit ? "关闭" : "取消"
        }}</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import until from "../../common/util";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    DefluteValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultEdeit: Boolean,
  },

  components: {
    // carDetail,
  },
  data() {
    return {
      config: {
        filter: {
          add: {
            addBtnName: "添加供需",
            ca: "supply_add",
          },
          width: 200,
          // filterBox: [
          //   {
          //     conditionName: "姓名",
          //     component: "input",
          //     field: "name",
          //     defaultValue: "",
          //   },
          // ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      deptList: [],
      supplyList: [
        { id: "0", name: "供应需求" },
        { id: "1", name: "采购需求" },
      ],
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      carDetail: {
        show: false,
        info: {},
      },
      farmList: [], //基地列表
      form: {
        userId: "",
        // demandType: "", //需求类型
        demandName: "", //需求名称
        demandRemark: "", //需求备注
        productId: "",
        productName: "", //货物名称
        demandNumber: "", //供应量描述
        demandNum: "", //数量
        demandUnit: "", //单位类型
        demandImgs: "", //供需图片
        demandAddress: "", //供需地址
        telPhone: "", //联系电话
        telName: "", //联系人
        publisherFarmId: "", //基地ID
      },
      rules: {
        publisherFarmId: [{ required: true, message: "请选择货品所在基地" }],
        demandName: [{ required: true, message: "请输入需求名称" }],
        // demandType: [{ required: true, message: "请选择需求类型" }],
        productId: [{ required: true, message: "请输入货物名称" }],
        demandNum: [{ required: true, message: "请输入货物数量" }],
        demandUnit: [{ required: true, message: "请输入单位类型" }],
        telName: [{ required: true, message: "请输入联系人" }],
        telPhone: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        demandAddress: [{ required: true, message: "请输入需求地址" }],
      },
    };
  },
  methods: {
    submit() {
      let params = { ...this.form, demandType: "0" };
      let url;
      for (let i = 0; i < this.deptList.length; i++) {
        if (params.productId == this.deptList[i].id) {
          params.productName = this.deptList[i].name;
        }
      }
      if (params.userId) {
        url = this.$api.SUPPLY_BIND.UPDATE;
      } else {
        url = this.$api.SUPPLY_BIND.ADD;
      }
      params.demandImgs = params.demandImgs.replace(
        /http:\/\/img.xd.sidwit.com\//g,
        ""
      );
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.userId ? "编辑成功！" : "添加成功！");
            this.DriverModel = false;
            this.$emit("input", false);
            this.$emit("refrestList");
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        userId: "",
        demandName: "", //需求名称
        // demandType: "", //需求类型
        demandRemark: "", //需求备注
        productName: "", //货物名称
        productId: "",
        demandNumber: "", //供应量描述
        demandNum: "", //数量
        demandUnit: "", //单位类型
        demandImgs: "", //供需图片
        demandAddress: "", //供需地址
        telPhone: "", //联系电话
        telName: "", //联系人
      };
      this.$refs.form.resetFields();
      this.farmList = []; //基地列表
    },
    // 选定基地时 填充地址
    getAddress(farmId) {
      let farm;
      try {
        farm = this.farmList.find((item) => item.id == farmId);
      } catch {
        farm = null;
      }
      if (farm) {
        this.form.demandAddress = farm.address;
      }
    },
    //获取产品列表
    getProductList() {
      this.$post(this.$api.PRODUCT_INFO.LIST)
        .then((res) => {
          this.deptList = res.list;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //获取基地列表
    getBaseList() {
      this.$post(this.$api.BASE_MANAGE.LIST)
        .then((res) => {
          this.farmList = res.list;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getBaseList();
    this.getProductList();
  },
  watch: {
    value(val) {
      if (val) {
        if (this.DefluteValue) {
          //执行初始化model操作
          let formData = {};
          formData = JSON.parse(JSON.stringify(this.DefluteValue));
          for (let i = 0; i < this.deptList.length; i++) {
            if (formData.productId == this.deptList[i].id) {
              this.form.productId = this.deptList[i].id;
            }
          }
          // for (let y = 0; y < this.supplyList.length; y++) {
          //   if (formData.demandType == this.supplyList[y].id) {
          //     this.form.demandType = this.supplyList[y].id;
          //   }
          // }
          this.form.userId = formData.id;
          this.form.demandName = formData.demandName; //需求名称
          this.form.demandRemark = formData.demandRemark; //需求备注
          this.form.productName = formData.productName; //货物名称
          this.form.demandNumber = formData.demandNumber; //供应量描述
          this.form.demandNum = formData.demandNum; //数量
          this.form.demandUnit = formData.demandUnit; //单位类型
          this.form.publisherFarmId = formData.publisherFarmId;
          this.form.demandImgs = formData.demandImgs
            ? formData.servicePath + formData.demandImgs
            : ""; //供需图片
          this.form.demandAddress = formData.demandAddress; //供需地址
          this.form.telPhone = formData.telPhone; //联系电话
          this.form.telName = formData.telName; //联系人
        }
      } else {
        //   执行重置数据操作
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>